import $ from 'jquery'

/**
 * --------------------------------------------------------------------------
 * Bootstrap (v4.0.0-beta.2): util.js
 * Licensed under MIT (https://github.com/twbs/bootstrap/blob/master/LICENSE)
 * --------------------------------------------------------------------------
 */

const Munki = (($) => {

//Smooth Scrolling For Internal Page Links
$(function() {
  $('a[href*=\\#spy]:not([href=\\#])').click(function() {
  if (location.pathname.replace(/^\//,'') == this.pathname.replace(/^\//,'') && location.hostname == this.hostname) {
    var target = $(this.hash);
    target = target.length ? target : $('[name=' + this.hash.slice(1) +']');
    if (target.length) {
    $('html,body').animate({
      scrollTop: target.offset().top - 60
    }, 1000);
    return false;
    }
  }
  });
});

  

	$('.carousel').carousel({
		interval: 5000
	})



  return Munki

})($)

export default Munki
